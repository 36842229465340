import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  AppBar,
  Button,
  useMediaQuery,
} from "@material-ui/core"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Divider } from "@material-ui/core"
import MenuRoundedIcon from "@material-ui/icons/MenuRounded"
import HomeIcon from "@material-ui/icons/Home"
import EventIcon from "@material-ui/icons/Event"
import GroupIcon from "@material-ui/icons/Group"
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard"
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew"
import MenuBookIcon from "@material-ui/icons/MenuBook"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import ContactMailIcon from "@material-ui/icons/ContactMail"
import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import FavoriteIcon from "@material-ui/icons/Favorite"
import { withStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  appBar: {
    minHeight: "5vh",
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "#474747",
  },
  menuButton: {
    color: "white",

    "&:hover": {
      animation: "rotate 2s infinite",
    },
  },
  title: {
    marginBottom: "5px",
  },
  link: {
    textDecoration: "none",
    color: "#474747",
    fontFamily: "Work Sans",
  },
  box: {
    float: "right",
  },
  drawer: {
    backgroundColor: "#0072ab",
  },

  tabListItem: {
    fontFamily: "Work Sans",
    fontSize: "1.2rem",
    textDecoration: "none",
    color: "white",
    textTransform: "capitalize",
  },
  icon: {
    fontSize: "2.5rem",
    color: "#0072ab",
  },
  button: {
    position: "fixed",
    zIndex: "10",
    margin: "8px",
  },
  menuIcons: {
    fontSize: "1.7rem",
    marginBottom: "2px",
    color: "white",
  },
  root: {
    position: "absolute",
  },
  toolbar: {
    marginLeft: "auto",
  },
  navItemLink: {
    textDecoration: "none",
    fontFamily: "Roboto",
    color: "#474747",
    margin: "20px 20px 0px 20px",
  },
  navItem: {
    fontSize: "1.2rem",
  },
}))

export default function Bar(props) {
  const [isOpen, setisOpen] = useState(false)
  const classes = useStyles()
  const isPhone = useMediaQuery("(max-width: 600px)")
  function toggleDrawer() {
    setisOpen(!isOpen)
  }

  const StyledButton = withStyles(theme => ({
    root: {
      borderRadius: "50px",
      backgroundColor: "#0072ab",
      color: "white",
      textTransform: "capitalize",
      fontSize: "1.1rem",

      "&:hover": {
        backgroundColor: "#177bad",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
  }))(Button)
  const sideList = side => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer}>
      <div style={{ height: "4vh" }} />
      <List>
        <ListItem
          component={AniLink}
          paintDrip
          hex="#ffffff"
          to="/home/"
          className={classes.tabListItem}
        >
          <HomeIcon className={classes.menuIcons} /> &nbsp;Home
        </ListItem>
        <ListItem
          component={AniLink}
          paintDrip
          hex="#ffffff"
          to="/events/"
          className={classes.tabListItem}
        >
          <EventIcon className={classes.menuIcons} /> &nbsp;Events
        </ListItem>
        <ListItem
          component={AniLink}
          paintDrip
          hex="#ffffff"
          to="/team/"
          className={classes.tabListItem}
        >
          <GroupIcon className={classes.menuIcons} /> &nbsp;Team
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={AniLink}
          paintDrip
          hex="#ffffff"
          to="/thisisme/"
          className={classes.tabListItem}
        >
          <AccessibilityNewIcon className={classes.menuIcons} /> &nbsp;This is
          Me
        </ListItem>
        <ListItem
          component={AniLink}
          paintDrip
          hex="#ffffff"
          to="/pamper/"
          className={classes.tabListItem}
        >
          <CardGiftcardIcon className={classes.menuIcons} /> &nbsp;Pamper
          Project
        </ListItem>
        <ListItem
          component={AniLink}
          paintDrip
          hex="#ffffff"
          to="/book/"
          className={classes.tabListItem}
        >
          <MenuBookIcon className={classes.menuIcons} /> &nbsp;Book
        </ListItem>
        <ListItem
          component={AniLink}
          paintDrip
          hex="#ffffff"
          to="/astonsway/"
          className={classes.tabListItem}
        >
          <LocalShippingIcon className={classes.menuIcons} /> &nbsp;Aston's Way
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={AniLink}
          paintDrip
          hex="#ffffff"
          to="/contact/"
          className={classes.tabListItem}
        >
          <ContactMailIcon className={classes.menuIcons} /> &nbsp;Contact
        </ListItem>
        <ListItem
          component="a"
          href="https://www.facebook.com/teamaveryessex"
          target="_blank"
          rel="noopen noreferrer"
          className={classes.tabListItem}
        >
          <FacebookIcon className={classes.menuIcons} /> &nbsp;Facebook
        </ListItem>
        <ListItem
          component="a"
          href="https://twitter.com/teamaveryessex"
          target="_blank"
          rel="noopen noreferrer"
          className={classes.tabListItem}
        >
          <TwitterIcon className={classes.menuIcons} /> &nbsp;Twitter
        </ListItem>
        <ListItem
          component="a"
          href="https://www.annakennedyonline.com"
          target="_blank"
          rel="noopen noreferrer"
          className={classes.tabListItem}
        >
          <FavoriteIcon className={classes.menuIcons} /> &nbsp;AnnaKennedy
        </ListItem>
      </List>
    </div>
  )

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <IconButton
          iconStyle={{ width: "60", height: "60" }}
          className={classes.button}
          float="right"
          onClick={() => {
            setisOpen(true)
          }}
        >
          <MenuRoundedIcon className={classes.icon} />
        </IconButton>
        <SwipeableDrawer
          variant="temporary"
          classes={{ paper: classes.drawer }}
          open={isOpen}
          onClose={toggleDrawer}
          anchor="left"
          BackdropProps={{ invisible: true }}
          disableSwipeToOpen
        >
          {sideList("left")}
        </SwipeableDrawer>
        <Toolbar className={classes.toolbar}>
          <>
            {isPhone === false && (
              <>
                <AniLink
                  to="/home/"
                  paintDrip
                  hex="#ffffff"
                  className={classes.navItemLink}
                >
                  <Typography className={classes.navItem}>Home</Typography>
                </AniLink>
                <AniLink
                  to="/events/"
                  paintDrip
                  hex="#ffffff"
                  className={classes.navItemLink}
                >
                  <Typography className={classes.navItem}>Events</Typography>
                </AniLink>{" "}
              </>
            )}
            <AniLink
              to="/book/"
              paintDrip
              hex="#ffffff"
              className={classes.navItemLink}
            >
              <StyledButton variant="contained">Buy Our Book</StyledButton>
            </AniLink>
          </>
        </Toolbar>
      </AppBar>
    </div>
  )
}
