import React from "react"
import Bar from "./bar"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import { makeStyles, withStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  main: {
    minHeight: "calc(100vh - 90px)",
  },
}))

export default function Layout(props) {
  const classes = useStyles()
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <link
          href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&family=Open+Sans&family=Jost&family=Nunito&family=Roboto&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
        />
      </Helmet>
      <Bar />
      <div className={classes.main}>{props.children} </div>
      <Footer />
    </>
  )
}
