import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Grid,
  IconButton,
  Typography,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core"
import { Link } from "gatsby"
import JaackIcon from "../img/Jack.inline.svg"
const useStyles = makeStyles(theme => ({
  appBar: {
    width: "100%",
    backgroundColor: "transparent",
    height: "90px",
    position: "relative",
    boxShadow: "none",
    color: "black",
  },
  menuButton: {
    color: "black",

    "&:hover": {
      animation: "rotate 2s infinite",
    },
  },
  title: {
    marginBottom: "5px",
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontFamily: "Work Sans",
  },
  box: {
    float: "right",
  },
  copyright: {
    bottom: "5px",
    paddingLeft: "15px",
    paddingRight: "25px",
    fontFamily: "Work Sans",
    fontSize: "0.95rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75rem",
    },
  },
  linkContainer: {
    marginTop: "-10px",
    color: "black",
  },
  link: {
    fontFamily: "Jost",
    textDecoration: "none",
    color: "black",
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75rem",
    },
  },
  jaackbutton: {
    "&:hover": {
      animation: "rotate 2s infinite",
    },
  },
  jaackcontainer: {
    display: "flex",
  },
}))

export default function Footer(props) {
  const classes = useStyles()
  const isPhone = useMediaQuery("(max-width: 600px)")
  return (
    <footer className={classes.appBar}>
      <div className={classes.jaackcontainer}>
        <Toolbar
          style={{
            flexDirection: "column ",
            padding: "0",
            marginLeft: "-10px",
          }}
        >
          <IconButton
            style={{ padding: "0px" }}
            className={classes.jaackbutton}
            color="inherit"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.jaack.dev"
            >
              <JaackIcon />
            </a>
          </IconButton>
          <Typography variant="h6" className={classes.linkContainer}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
              href="https://www.jaack.dev"
            >
              Developed by jaack.dev
            </a>
          </Typography>
          <div className={classes.copyright}>
            &#169; Team Avery Essex {new Date().getFullYear()}{" "}
          </div>
        </Toolbar>
      </div>
    </footer>
  )
}
